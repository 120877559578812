const Peer = window.Peer;
let peer = new Peer();
let CurrentRoom = false;
let peers = {};

const getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia;

// Call new person

let exports = {
  GetCurrentRoom() {
    return CurrentRoom;
  },
  Init(socket) {
    socket = socket || window.socket;
    socket.on("vc-join", (newPeerId) => {
      if (!CurrentRoom) return;
      getUserMedia(
        { video: false, audio: true },
        function(stream) {
          let call = peer.call(newPeerId, stream);
          peers[call.peer] = call;
          let player = new Audio();
          call.on("stream", function(remoteStream) {
            console.log("playing stream from", newPeerId);
            player.srcObject = remoteStream;
            player.play();
          });
          call.on("close", () => {
            player.remove();
            delete peers[call.peer];
          });
        },
        function(err) {
          console.log("Failed to get local stream", err);
        }
      );
    });

    // Answer calls
    peer.on("call", function(call) {
      if (!CurrentRoom) return;
      getUserMedia(
        { video: false, audio: true },
        function(stream) {
          call.answer(stream); // Answer the call with an A/V stream.
          peers[call.peer] = call;
          let player = new Audio();
          call.on("stream", function(remoteStream) {
            console.log("playing stream from", "some guy");
            player.srcObject = remoteStream;
            player.play();
          });
          call.on("close", () => {
            player.remove();
            delete peers[call.peer];
          });
        },
        function() {
          alert("Failed to get your mic");
        }
      );
    });
  },
  JoinRoom(room, socket) {
    if (CurrentRoom) return console.log("Already in room");
    socket = socket || window.socket;

    socket.emit("vc-join", room, peer.id);

    CurrentRoom = room;
  },
  LeaveRoom(room, socket) {
    if (!CurrentRoom) return console.log("Not in room", CurrentRoom);
    socket = socket || window.socket;

    socket.emit("vc-leave", room, peer.id);

    for (let peer of Object.keys(peers)) {
      peers[peer].close();
      delete peers[peer];
    }

    CurrentRoom = false;
  },
};

module.exports = exports;
window.vc = exports;
