<template>
  <div :class="`LoginWindow ${!hidden ? `` : `hidden`}`">
    <img src="/img/LogoMiniDT.svg" alt="Vooz" />
  </div>
</template>

<script>
export default {
  name: "LoginChecker",
  mounted() {
    let gapi = window.gapi ? window.gapi : {};

    window.loginSucceded = (u) => {
      this.LoginSuccess(u);
    };

    let loginButton = document.createElement("div");
    loginButton.setAttribute("class", "g-signin2");
    loginButton.setAttribute("data-onsuccess", "loginSucceded");
    document.querySelector(".LoginWindow").innerHTML += loginButton.outerHTML;

    window.signOut = () => {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        window.location.reload();
      });
    };
  },
  components: {},
  directives: {},
  data() {
    return {
      hidden: false,
    };
  },
  props: {
    onLogin: Function,
  },
  methods: {
    LoginSuccess(user) {
      window.userInfo = user.getBasicProfile();
      document.querySelector("div.LoginWindow").classList.toggle("hidden");
      this.onLogin(user);
    },
    LoginFailed() {
      alert("Login failed, reload page and try again.");
    },
    OnGoogleAuthSuccess(idToken) {
      this.LoginSuccess(idToken);
    },
    OnGoogleAuthFail(error) {
      console.log(error);
      this.LoginFailed();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.LoginWindow {
  background-color: #323232;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
}
.hidden {
  display: none;
  pointer-events: none;
}
</style>
