<template>
  <div class="header">
    <div class="leftPart">
      <img :src="UserImage" alt="pfp" />
      <p>{{ UserName }}</p>
      <button @click="Logout">Sign out</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    UserName: String,
    UserImage: String,
    Logout: Function,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  height: 2.5rem;
  background-color: #161616;
  flex-direction: row;
  color: white;
}
.leftPart {
  display: flex;
  align-items: center;
  flex-direction: row;
}
img {
  height: 2.5rem;
  border-radius: 50%;
}
p {
  margin: 0px 1rem;
}
button {
  border: none;
  border-radius: 0.4rem;
  background-color: rgb(200, 0, 0);
  font-size: 1.2rem;
  padding: 0.4rem;
  color: white;
  transition: background-color ease-in-out 0.2s;
}
button:hover {
  background-color: rgb(240, 0, 0);
  cursor: pointer;
}
</style>
