<template>
  <div class="sidebar">
    <div class="tools">
      <div @click="addRoom" class="add">
        <p>Add</p>
        <img src="@/assets/ex/plus.svg" />
      </div>
    </div>
    <div class="options">
      <div class="option" v-for="data of options" :key="data">
        <router-link
          :to="data !== 'loading chatrooms...' ? `/room/${data}` : '#'"
        >
          <p class="name">{{ data }}</p>
        </router-link>
        <img
          src="@/assets/ex/close.svg"
          @click="delRoom.bind(null, data)()"
          alt="del"
          class="remove"
        />
      </div>
    </div>
    <ConnectionBox v-bind="ConnectionManager"></ConnectionBox>
  </div>
</template>

<script>
import ConnectionBox from "@/components/ConnectionBox.vue";

export default {
  name: "Header",
  props: {
    options: Array,
    ConnectionManager: Object,
  },
  data() {
    return {};
  },
  components: {
    ConnectionBox,
  },
  methods: {
    async addRoom() {
      let swal = window.swal;
      let axios = window.axios;

      let result = await swal("Enter chatroom", {
        content: "input",
      });
      if (result.split(" ").join("") !== result) {
        return swal({
          title: "You can't have spaces dude, come on.",
          icon: "error",
        });
      }
      let params = new URLSearchParams({
        newRoom: result,
      });
      await axios.post(
        `https://swz.works/vooz-internal/chatrooms/${
          window.usertoken
        }?${params.toString()}`
      );
      this.$parent.Connected(window.usertoken);
      swal({
        title: "",
        icon: "success",
      });
    },
    async delRoom(result) {
      let swal = window.swal;
      let axios = window.axios;

      if (
        !(await swal({
          title: "Are you sure?",
          text: "Are you sure that you want to leave this room?",
          icon: "warning",
          dangerMode: true,
        }))
      ) {
        return;
      }

      let params = new URLSearchParams({
        newRoom: result,
      });
      await axios.delete(
        `https://swz.works/vooz-internal/chatrooms/${
          window.usertoken
        }?${params.toString()}`
      );
      this.$parent.Connected(window.usertoken);
      swal({
        title: "",
        icon: "success",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar {
  min-width: 10rem;
  max-width: 15rem;
  background-color: #161616;
  display: flex;
  flex-direction: column;

  height: 100%;

  color: #fff;
}
.options {
  display: flex;
  flex-direction: column;
  margin: 0px 0.5rem;
  width: calc(100% - 1rem);
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 0.5rem;
  margin: 0.3rem 0px;
  border-radius: 0.5rem;
  user-select: none;
  cursor: pointer;
  appearance: none;
  background-color: #323232;
  transition: background-color 0.3s;
}
.option:hover {
  background-color: #282828;
}
img {
  height: 1.5rem;
  width: auto;
}
.add {
  background-color: #323232;
  height: 2.5rem;
  display: flex;
  margin-top: 0.5rem;
  margin-left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 0.5rem;
  transition: background-color 0.3s;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}
.add:hover {
  background-color: #282828;
}
.add p {
  margin-right: 0.5rem;
}
.option a {
  flex-grow: 1;
  overflow: hidden;
}
.remove {
  position: relative;
  right: 0px;
}
p {
  margin: 0px;
  padding: 0px;
  appearance: none;
  text-decoration: none white;
  color: white;
}
</style>
