<template>
  <div id="app">
    <LoginChecker :onLogin="Load"></LoginChecker>
    <Header v-bind="UserData"></Header>
    <div class="mainWindow">
      <Sidebar
        :options="Chatrooms"
        :ConnectionManager="{
          Name: vcClient.GetCurrentRoom,
          Disconnect: vcClient.LeaveRoom,
        }"
      ></Sidebar>
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import LoginChecker from "@/components/LoginChecker.vue";
import * as VoiceChat from "@/VoiceChat.js";

// window.vcClient = VoiceChat;

export default {
  name: "Home",
  components: {
    LoginChecker,
    Header,
    Sidebar,
  },
  data() {
    return {
      UserData: undefined,
      Chatrooms: [
        "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
        // "loading chatrooms...",
      ],
      vcClient: VoiceChat,
      messages: {},
    };
  },
  methods: {
    Connected(usertoken) {
      let axios = window.axios;
      axios
        .get(`https://swz.works/vooz-internal/chatrooms/${usertoken}`)
        .then((res) => {
          this.Chatrooms = res.data;
        })
        .catch((e) => {
          e;
        });
      this.$router.push("/");
    },
    ConnectToSocket(usertoken) {
      let io = window.io;
      let swal = window.swal;
      // usertoken =
      //   "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxOTdiZjJlODdiZDE5MDU1NzVmOWI2ZTVlYjQyNmVkYTVkNTc0ZTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMzUyMzQ2OTIxNTYtNXN2ZDFnY3NqNjdjOXJ2amk3dnNmNGlja3R0NXEyYmMuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIzNTIzNDY5MjE1Ni01c3ZkMWdjc2o2N2M5cnZqaTd2c2Y0aWNrdHQ1cTJiYy5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExNTQ5NjU4NzAyMzI1NDM2MDE3MiIsImVtYWlsIjoic2ltb24ubGluZGdyZW4wN0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IlNkSEo2QU1MdjZwcmQxazAtejJvU1EiLCJuYW1lIjoiU2ltb24gTGluZGdyZW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2hPUzgxTjV3eHRNNFNCZmxvb3Bic2hsSG4xWklKVGpvNzZaVG50PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlNpbW9uIiwiZmFtaWx5X25hbWUiOiJMaW5kZ3JlbiIsImxvY2FsZSI6InN2IiwiaWF0IjoxNjA4MDU5MjY4LCJleHAiOjE2MDgwNjI4NjgsImp0aSI6ImM1YmY4ODAzNzlkNTU2NWNjMDEzZWY0M2JkMjlmOWE5YWNlZDIzY2YifQ.SKYaWbL9MHhVY7rp1W5TQWh9F3cQwkTciRBNV-MjbPmx1TpuoRiGYhOA_EsESaduT7SIRCROHseAJP5aPFSL37oKXEfDPU5KZEHWped9J2dV6vbfwehbu7BS93-CPm-h7mwfcnXHValulWBIKnqvyDsE5X5W6sW13p8G1-ASmzCmzqAaFt5ph78Bn-oAYhPEnfbcrRXAKLQsraFza39qAlPjjn0jvBQ0OhcaBUdFgIckZXCvBzWILHoNSb_eZ_d3PTxKcfynZNytZbd-mUNJLqbczr2xtbCzBuqtwR-k4Q4LD0YUytp02uKlqL4Pu0ICV_bayoESD2YJOx-d5LPVUh";
      console.log("Connecting to socket with idtoken:", usertoken);
      window.socket = io.connect(`https://swz.works?app=vooz2`);

      let socket = window.socket;

      console.log("Verifying socket");
      socket.emit("beginAuth", usertoken);

      socket.on("auth", (s) => {
        if (s) {
          console.log("Connected to socket successfully");
          this.Connected(usertoken);
          window.usertoken = usertoken;
          this.vcClient.Init();
        }
      });
      socket.once("disconnect", async () => {
        await swal({
          title: "You got disconnected, this will reload your page.",
          icon: "error",
        });
        location.reload();
      });

      socket.on("chat", (pack) => {
        if (!Object.keys(this.messages).includes(pack.room)) {
          this.$set(this.messages, pack.room, []);
        }
        let room = pack.room;
        delete pack.room;
        this.messages[room].push(pack);
      });
    },
    Load(user) {
      let userInfo = user.getBasicProfile();
      this.UserData = {};
      this.UserData.UserName = userInfo.getName();
      this.UserData.UserImage = userInfo.getImageUrl();
      this.UserData.Logout = () => {
        window.signOut();
      };
      this.ConnectToSocket(user.getAuthResponse().id_token);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #1f9aff 14%, #1f66ff 64%);
  border-radius: 10px;
  transition: background-color ease-in-out 1s;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #1f9aff 14%, #0051ff 64%);
}
::-webkit-scrollbar-track {
  background: #282828;
  border-radius: 0.6rem;
  /* box-shadow: inset 7px 10px 12px #f0f0f0; */
}

html,
body,
#app {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
  min-width: 35rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.mainWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

img.button {
  cursor: pointer;
  user-select: none;
}
</style>
