<template>
  <div class="connectionBox" v-if="Connected">
    <div class="statusBox">
      <p class="small">Connected to</p>
      <h2>{{ Name() }}</h2>
    </div>
    <img src="@/assets/ex/disconnect.svg" class="button" @click="Disconnect" />
  </div>
</template>

<script>
export default {
  name: "ConnectionBox",
  props: {
    Disconnect: Function,
    Name: Function,
  },
  data() {
    return { Connected: false };
  },
  mounted() {
    setInterval(() => {
      if (window.vc) {
        this.Connected = !!window.vc.GetCurrentRoom();
      }
    }, 10);
  },
};
</script>

<style scoped>
.connectionBox {
  display: flex;
  max-height: 4rem;
  min-width: 14rem;
  background-color: #080808;
  border-top-right-radius: 1rem;
}
.statusBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
}
img {
  height: 100%;
  width: auto;
  padding: 0.4rem;
}
.small {
  color: gray;
}
</style>
